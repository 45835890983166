/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import {
  alpha,
  Box,
  Button,
  ButtonBase,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CheckCircleRounded, Close } from '@mui/icons-material';
import clsx from 'clsx';
import * as routes from '@educabot/educablocks-cosmos';
import { useDispatch, useSelector } from 'react-redux';
import { getYear } from 'date-fns';
import { useCheckLogged } from '@utils/hooks';
import { createLabsProfile, deleteUserProfile, updateUserProfile } from '@services/rest/creabots';
import LabsLogo from '@assets/icons/labsLogo';
import { useIntl } from 'react-intl';
import { selectCreabots, setDeleteCurrentProfile, setNewProfile } from '../features/creabotsSlice';
import { useGetLabsProfiles } from '../features/hooks/useGetLabsProfiles';
import { ConfirmDeleteLabsModal } from './confirmDeleteLabsModal';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100dvh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1em 5em',
  },
  wrapper: {
    width: '100%',
    marginTop: '4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '3.5rem',
  },
  formWrapper: {
    width: 456,
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
  },
  formTitle: {
    marginBottom: '3.5rem',
    fontSize: '2.5rem',
    fontFamily: 'DM Sans',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.black,
    lineHeight: '52px',
    letterSpacing: '-0.02em',
  },
  inputRoot: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: alpha(theme.palette.common.black, 0.23),
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(66, 179, 255, 0.5)',
      },
      color: theme.palette.common.black,
    },
    fontWeight: theme.typography.fontWeightMedium,
  },

  error: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: alpha(theme.palette.error.main, 0.5),
      },
      '&:hover fieldset': {
        borderColor: alpha(theme.palette.error.main, 0.5),
      },
      '&.Mui-focused fieldset': {
        borderColor: alpha(theme.palette.error.main, 0.5),
      },
    },
    color: theme.palette.error,
  },
  errorText: {
    marginTop: '0.4em',
    paddingLeft: '1em',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    textAlign: 'left',
    color: '#F45C3A',
    fontFamily: 'DM Sans, sans-serif',
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '3em',
    marginBottom: '3.5rem',
  },
  inputControl: {
    position: 'initial',
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  inputLabel: {
    fontSize: '1rem !important',
    fontFamily: 'DM Sans',
    fontWeight: theme.typography.fontWeightMedium,
    color: '#1A1A1A !important',
    textAlign: 'left',
    position: 'initial !important',
  },
  avatar: {
    position: 'relative',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  avatarSelected: {
    boxShadow: '0px 0px 0px 4px #42B3FF',
  },
  selectedAvatarIcon: {
    position: 'absolute',
    top: 4,
    right: 2,
    height: 24,
    width: 24,
    color: '#42B3FF',
    backgroundColor: theme.palette.common.white,
    borderRadius: '50%',
  },

  rootSelect: {
    '& .MuiSelect-root.MuiSelect-select': {
      '&:focus': {
        backgroundColor: theme.palette.common.white,
      },
    },
    '&.MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23) !important',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(66, 179, 255, 0.5) !important',
      },
    },
    fontWeight: 500,
    color: 'black',
    fontFamily: 'DM Sans',
  },
  selectAvatarText: {
    textAlign: 'center',
    fontFamily: 'DM Sans',
    fontSize: '1rem',
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '24px',
  },
}));

const avatars = [
  {
    slug: 'ester',
    src: `${routes.bloquesUri}/images/creabots/profiles/ester.png`,
  },
  {
    slug: 'daniel',
    src: `${routes.bloquesUri}/images/creabots/profiles/daniel.png`,
  },
  {
    slug: 'ubaldo',
    src: `${routes.bloquesUri}/images/creabots/profiles/ubaldo.png`,
  },
  {
    slug: 'cloe',
    src: `${routes.bloquesUri}/images/creabots/profiles/cloe.png`,
  },
  {
    slug: 'aike',
    src: `${routes.bloquesUri}/images/creabots/profiles/aike.png`,
  },
];

function generateYearRange() {
  const currentYear = getYear(new Date());

  const startYear = currentYear - 2;
  const endYear = currentYear - 100;

  const yearArray = [];
  for (let year = startYear; year >= endYear; year--) {
    yearArray.push(year.toString());
  }

  return yearArray;
}

const getFileNameWithoutExtension = (url) => {
  const parts = url.split('/');
  const fileName = parts[parts.length - 1];
  return fileName.split('.')[0];
};

export const CreabotsProfileModal = ({
  isOpen,
  onClose,
  isEditMode,
  profileToEdit,
}) => {
  const { data } = useCheckLogged(true, '/kits/ingresar');
  const { refetch } = useGetLabsProfiles();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const styles = useStyles();
  const dispatch = useDispatch();
  const yearsList = generateYearRange();
  const userProjects = useSelector(selectCreabots).projects
  const [avatarSelected, setAvatarSelected] = useState('ester');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDeleteAction, setIsLoadingDeleteAction] = useState(false);
  const intl = useIntl();

  const [fields, setFields] = useState({
    name: '',
    birth: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    birth: '',
    request: '',
  });

  const handleChange = (e) => {
    setErrors({
      ...errors,
      [e.target.name]: '',
      request: '',
    });
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  const handleClose = () => {
    setIsLoading(false);
    setIsOpenDeleteModal(false);
    setIsLoadingDeleteAction(false);
    setFields({ name: '', birth: '' });
    setErrors({ name: '', birth: '', request: '' });
    setAvatarSelected('ester');
    onClose();
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!fields.name && !fields.birth) {
      setErrors({
        name: intl.formatMessage({ id: 'creabots.validations.insertName' }),
        birth: intl.formatMessage({ id: 'creabots.validations.insertBirth' }),
      });
      return;
    }
    if (!fields.name) {
      setErrors((prev) => ({ ...prev, name: intl.formatMessage({ id: 'creabots.validations.insertName' }) }));
      return;
    }
    if (!fields.birth) {
      setErrors((prev) => ({
        ...prev,
        birth: intl.formatMessage({ id: 'creabots.validations.insertBirth' }),
      }));
      return;
    }
    try {
      setIsLoading(true);
      const parentId = data.user.parentId ? data.user.parentId : data.userId

      if (!profileToEdit) {
        // if the user has a parent id, then it's a profile, not a parent
        const createProfileDto = {
          ...fields,
          parentId,
          avatarUrl: avatars.find((avatar) => avatar.slug === avatarSelected).src,
        };
        const resp = await createLabsProfile(createProfileDto);

        dispatch(setNewProfile({ ...createProfileDto, id: resp.data.id }));
        return
      }

      const updateProfileDto = {
        name: fields.name,
        birth: fields.birth,
        avatarUrl: avatars.find((avatar) => avatar.slug === avatarSelected).src,
        id: profileToEdit.id,
        parentId,
      };
      await updateUserProfile(updateProfileDto);
    } catch (error) {
      setErrors((prev) => ({
        ...prev,
        request:
          error.response?.data?.message || intl.formatMessage({ id: 'common.errors.somethingWentWrong' }),
      }));
    } finally {
      refetch();
      handleClose();
    }
  };

  useEffect(() => {
    if (isEditMode && profileToEdit) {
      setFields({
        name: profileToEdit.firstName,
        birth: profileToEdit.birth.split('-')[0],
      });
      setAvatarSelected(getFileNameWithoutExtension(profileToEdit.avatarUrl || ''));
    }
  }, [profileToEdit]);


  const handleConfirmAction = async () => {
    try {
      setIsLoadingDeleteAction(true);
      await deleteUserProfile(profileToEdit.id);
      dispatch(setDeleteCurrentProfile(data.userId === profileToEdit.id));
      handleClose();
      refetch();
    } catch (error) {
      setIsLoadingDeleteAction(false);
    }
  }

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-de-perfiles"
        aria-describedby="modal-para-crear-editar-perfiles"
        sx={{
          overflowY: 'auto',
        }}
      >
        <Box className={styles.container}>
          <Box className={styles.header}>
            {isEditMode ? (
              <LabsLogo />
            ) : (
              <Typography
                sx={(theme) => ({
                  fontSize: '2em',
                  fontFamily: 'DM Sans',
                  fontWeight: theme.typography.fontWeightRegular,
                  color: theme.palette.common.black,
                  lineHeight: '28px',
                  letterSpacing: '-0.015em',
                })}
              >
                {intl.formatMessage({ id: 'creabots.profiles.newProfile' })}
              </Typography>
            )}
            <IconButton onClick={handleClose}>
              <Close
                sx={() => ({
                  color: '#6D7878',
                  height: 24,
                  width: 24,
                })}
              />
            </IconButton>
          </Box>
          <Box className={styles.wrapper}>
            <form className={styles.formWrapper} onSubmit={handleSubmit}>
              <Typography
                sx={(theme) => ({
                  marginBottom: '3.5rem',
                  fontSize: '2.5rem',
                  fontFamily: 'DM Sans',
                  fontWeight: theme.typography.fontWeightBold,
                  color: theme.palette.common.black,
                  lineHeight: '52px',
                  letterSpacing: '-0.02em',
                })}
              >
                {!isEditMode && 'Configurar el perfil'}
                { profileToEdit && isEditMode && 'Editar perfil' }
                {!profileToEdit && isEditMode && 'Nuevo perfil' }
              </Typography>
              <Grid container className={styles.inputsContainer}>
                <Box
                  item
                  xs={12}
                  className={clsx(
                    'form-group bmd-form-group',
                    styles.inputControl,
                  )}
                >
                  <InputLabel
                    shrink={false}
                    htmlFor="name"
                    className={clsx('bmd-form-group bmd-label-static')}
                    sx={(theme) => ({
                      fontSize: '1rem !important',
                      fontFamily: 'DM Sans',
                      fontWeight: theme.typography.fontWeightMedium,
                      color: '#1A1A1A !important',
                      textAlign: 'left',
                      position: 'initial !important',
                    })}
                  >
                    {intl.formatMessage({ id: 'creabots.labels.childName' })}
                  </InputLabel>
                  <TextField
                    autoFocus
                    type="text"
                    name="name"
                    id="name"
                    fullWidth
                    variant="outlined"
                    value={fields.name}
                    error={!!errors.name || !!errors.request}
                    onChange={handleChange}
                    className={styles.inputRoot}
                  />
                  {errors.name && (
                    <Typography className={styles.errorText}>
                      {errors.name}
                    </Typography>
                  )}
                </Box>
                <Box
                  item
                  xs={12}
                  className={clsx(
                    'form-group bmd-form-group',
                    styles.inputControl,
                  )}
                >
                  <InputLabel
                    shrink={false}
                    htmlFor="birth"
                    className={clsx('bmd-form-group bmd-label-static')}
                    sx={(theme) => ({
                      fontSize: '1rem !important',
                      fontFamily: 'DM Sans',
                      fontWeight: theme.typography.fontWeightMedium,
                      color: '#1A1A1A !important',
                      textAlign: 'left',
                      position: 'initial !important',
                    })}
                  >
                    {intl.formatMessage({ id: 'creabots.labels.birth' })}
                  </InputLabel>
                  <Select
                    variant="outlined"
                    id="birth"
                    labelId="birth"
                    name="birth"
                    value={fields.birth}
                    error={!!errors.birth || !!errors.request}
                    onChange={handleChange}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 48 * 4.5,
                          width: 250,
                        },
                      },
                    }}
                    className={styles.rootSelect}
                  >
                    {yearsList.map((year) => (
                      <MenuItem value={year} key={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.birth && (
                    <Typography className={styles.errorText}>
                      {errors.birth}
                    </Typography>
                  )}
                </Box>
              </Grid>
              {errors.request && (
                <Typography className={styles.errorText}>
                  {errors.request}
                </Typography>
              )}
              <Button
                variant="primary"
                size="large"
                type="submit"
                disabled={isLoading || !!errors.request}
              >
                {intl.formatMessage({ id: 'common.ready' })}
              </Button>
              {isEditMode && profileToEdit && (
                <Button
                  variant="text"
                  sx={(theme) => ({
                    marginTop: 1,
                    height: 48,
                    fontFamily: 'DM Sans',
                    borderRadius: 2,
                    fontSize: '1.8em',
                    fontWeight: theme.typography.fontWeightMedium,
                    color: theme.palette.error.main,
                    letterSpacing: '-0.015em',
                    textTransform: 'capitalize',
                  })}
                  onClick={() => setIsOpenDeleteModal(true)}
                >
                  {intl.formatMessage({ id: 'creabots.profiles.deleteProfile' })}
                </Button>
              )}
            </form>
            <Box
              sx={{
                width: 456,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Typography className={styles.selectAvatarText}>
                {intl.formatMessage({ id: 'creabots.profiles.selectAvatar' })}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 4,
                  width: '100%',
                }}
              >
                {avatars.map((avatar) => (
                  <ButtonBase
                    key={avatar.slug}
                    className={styles.avatar}
                    onClick={() => setAvatarSelected(avatar.slug)}
                    disableRipple
                  >
                    <img
                      src={avatar.src}
                      alt={avatar.slug}
                      style={{
                        objectFit: 'contain',
                        width: 120,
                        height: 120,
                        borderRadius: '50%',
                        ...(avatarSelected === avatar.slug && {
                          outline: '4px solid rgba(66, 179, 255, 0.5)',
                          border: '1px solid white',
                        }),
                      }}
                    />
                    {avatarSelected === avatar.slug && (
                      <CheckCircleRounded
                        className={styles.selectedAvatarIcon}
                      />
                    )}
                  </ButtonBase>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      {isEditMode && profileToEdit && (
      <ConfirmDeleteLabsModal
        isOpen={isOpenDeleteModal}
        handleClose={() => setIsOpenDeleteModal(false)}
        onConfirmAction={handleConfirmAction}
        isLoadingDeleteAction={isLoadingDeleteAction}
        name={profileToEdit.firstName}
        avatarUrl={profileToEdit.avatarUrl}
        description={userProjects.length}
        isProject={false}
      />
      )}
    </>
  );
};
